// import React, { useState, useEffect } from "react";
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Form, Input, InputNumber, Button, Space, Select, DatePicker } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
// import useWindowDimensions from '../utils/utils'
import { useWindowDimensions  } from '../utils/utils';
import { openNotificationWithIcon} from '../utils/utils';


const { Option } = Select;


const DynamicFormSet = (props) => {

  const [loading, setLoading] = useState(true);
  const { height, width } = useWindowDimensions();

  
  useEffect(() => {
  }, []);
  const config = {
    rules: [
      {
        type: 'object',
        required: true,
        message: 'Please select a time!',
      },
    ],
  };
  const [form] = Form.useForm();

  const onFinish = values => {
    // form.resetFields();
    for (let value of values.line_items) {
      value.organisation = props.org_id;
      value.date = value.date.format("YYYY-MM-DD");
    }
    //need to use the user_id to get the itemcodes that are assigned to the user. populate the employee tag with that. Then get materials by org and populate that
        // openNotificationWithIcon('success', 'Items deleted!')
    axios.post('/backend/line_items/', { values: values.line_items }, {})
    .then((resp)=> {openNotificationWithIcon('success', 'Items created')})
    .catch((err)=> {openNotificationWithIcon('error', 'Unable to save')})
    //TODO: check outcome of response, send notification on the status of the timesheet

    form.resetFields();

  };

  const formItemLayout={
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 4,
      },
    }
  

  return (
    <Form name="dynamic_form_nest_item" layout={formItemLayout} form={form} onFinish={onFinish} autoComplete="off">
      <Form.List name="line_items">
        {(fields, { add, remove }) => {
          return (
            <div>
              {fields.map(field => (
                <Space key={field.key} style={{ display: 'flex', marginBottom: 8 , flexDirection: width < 650 ? "column": null}} align="start">

                  <Form.Item
                    {...field}
                    style={{minWidth:"200px"}}
                    name={[field.name, "item_code"]}
                    fieldKey={[field.fieldKey, 'item_code']}
                    rules={[
                      {
                        required: true,
                        message: 'Please select an item',
                      },
                    ]}>

                    <Select
                      showSearch
                      placeholder="Select a item"
                      optionFilterProp="children"
                      // disabled={props.loading.products}
                      dropdownMatchSelectWidth={true}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {props.products.map(({ label, value }) => (
                        <Option value={value}>
                          {label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    {...field}
                    name={[field.name, 'quantity']}
                    fieldKey={[field.fieldKey, 'quantity']}
                  >
                    <InputNumber placeholder={props.tab === "employee" ? "Hours" : "Quantity"} step={1.0} />
                  </Form.Item>
                  {props.tab === "custom" ?
                    <Form.Item
                      {...field}
                      name={[field.name, 'price']}
                      fieldKey={[field.fieldKey, 'price']}
                    >
                      <InputNumber placeholder={"Cost per unit"} step={.1} />
                    </Form.Item>
                    : null}
                  <Form.Item
                    {...field}
                    name={[field.name, 'description']}
                    fieldKey={[field.fieldKey, 'description']}
                    rules={[{ required: true, message: 'Missing last name' }]}
                  >
                    <Input placeholder="Description" />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    name={[field.name, "work_order"]}
                    fieldKey={[field.fieldKey, 'workorder']}
                    rules={[
                      {
                        required: true,
                        message: 'Please select a workorder',
                      },
                    ]}>

                    <Select
                      showSearch
                      placeholder="Select a workorder"
                      optionFilterProp="children"
                      // disabled={loading}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {props.workorders.map(({ label, value }) => (
                        <Option key={value} value={value}>
                          {label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    {...field}
                    name={[field.name, "date"]}
                    fieldKey={[field.fieldKey, "date"]}
                    {...config}>
                    <DatePicker format="DD-MMM-YYYY" />
                  </Form.Item>
                  <MinusCircleOutlined
                    onClick={() => {
                      remove(field.name);
                    }}
                  />
                </Space>
              ))}

              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => {
                    add();
                  }}
                  block
                >
                  <PlusOutlined /> Add Line Item
                </Button>
              </Form.Item>
            </div>
          );
        }}
      </Form.List>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

const mapStateToProps = state => {
  return {
    token: state.token,
    // username: state.username,
    // user_id: state.user_id,
    org: state.org,
    org_id: state.org_id,
  }
}
export default connect(mapStateToProps)(DynamicFormSet);
