import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import CreateLineItems from './containers/CreateLineItems';
import OrgSettings from './components/OrgSettings';
import LineItemList from './containers/LineItemList';
import UserInfo from './containers/UserInfo';
import HoursWorked from './containers/HoursWorked';
import ManageUsers from './containers/ManageUsers';
import ReviewLineItemList from './containers/ReviewLineItemList';
import Login from './containers/Login';
import SignUp from './containers/SignUp';
import OrgSetup from './containers/OrgSetup';
import WorkOrders from './containers/WorkOrders';

export const PrivateRoute = ({ component: Component, ...rest }) => (
    < Route {...rest} render={props =>
        localStorage.getItem("isAuthenticated") ? (
            <Component {...props} />
        ) : (
                <Redirect
                    to={{
                        pathname: "/login"
                    }}
                />
            )
    }
    />);


const BaseRouter = () => (
    <Switch>
        <PrivateRoute path={'/timesheet/create/'} component={CreateLineItems} />
        <PrivateRoute path={'/timesheet/list/'} component={LineItemList} />
        <PrivateRoute path={'/timesheet/review/'} component={ReviewLineItemList} />
        <PrivateRoute path={'/workorder/list/'} component={WorkOrders} />
        <PrivateRoute path={'/workorder/hours/'} component={HoursWorked} />
        <PrivateRoute path={'/settings/userinfo'} component={UserInfo} />
        <PrivateRoute path={'/settings/manageusers'} component={ManageUsers} />
        <PrivateRoute path={'/settings/organisation'} component={OrgSettings} />
        <PrivateRoute path={'/settings/orgsetup'} component={OrgSetup} />
        {/* <Redirect from="/" to="/timesheet/create" /> */}
        {/* TODO: make this a nicer redirect */}
        <Route exact path='/signup/' component={SignUp} />
        <Route exact path='/login/' component={Login} />
        <Route exact path="/oauth" render={() => {window.location.origin === "http://localhost:3000" ? window.location = `http://localhost:8000/backend/oauth/` : window.location = `${window.location.origin}/backend/oauth/`}} />
        <Route path="/" component={Login}/>
    </Switch>
);
export default BaseRouter;
